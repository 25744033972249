/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Layout from '../components/layout';
import SEO from '../components/seo';
import VvVideo from '../assets/videos/vv-v1-2.mp4';
import GridBlock from '../components/gridBlock';
import LogoDisplay from '../components/logoDisplay';
import AniLink from 'gatsby-plugin-transition-link/AniLink';


const IndexPage = () => {

  const blockCSS = 'flex flex-auto group w-full relative h-1/8 border-0';
  const colorCSS =
    'transition h-full w-full absolute opacity-10 group-hover:opacity-30';
  return (
    <Layout to="/" label="home" showMenuIcon className="bg-black ">
      <SEO title="Vivo Vibe" />
      <section className="h-screen w-screen">
        <div className="absolute w-screen h-screen">
          <video
            autoPlay
            muted
            playsInline
            loop
            className="object-cover h-full w-full absolute"
          >
            <source src={VvVideo} type="video/mp4" />
          </video>
          <div className="w-full h-full overlay absolute"></div>
        </div>
        <div className="relative flex h-screen w-screen justify-center flex-col p-20">
            <div class="grid text-center text-3xl pb-10 vivo-cont">

      Grow Content
</div>
      <div class="grid grid-cols-2 gap-10 text-3xl text-center pb-10">
      
            </div>
            </div>
      </section>

      </Layout>
  );
};

export default IndexPage;
